import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user';
import usesrReducer from './users';
import statusReducer from './status';
import dealershipReducer from './dealership';
import ticketReducer from './ticket';
import modalReducer from './modal';
import partReducer from './part';
import bikeReducer from './bike';
import serviceCenterReducer from './serviceCenter';
import actionReducer from './actions';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    users: usesrReducer,
    status: statusReducer,
    dealership: dealershipReducer,
    ticket: ticketReducer,
    modal: modalReducer,
    part: partReducer,
    bike: bikeReducer,
    serviceCenter: serviceCenterReducer,
    actions: actionReducer
  });

export default createRootReducer;
