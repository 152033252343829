import React from 'react';
import PropTypes from 'prop-types';
import DataTable, { createTheme } from 'react-data-table-component';
import styled, { css } from 'styled-components';
import { typography, colors, space } from 'Theme';
import { SortIcon, Chevron } from 'Assets/images/icons';
import { formatPhoneNumber } from '../Utils';
import Throbber from '../Throbber';

const { fontSizes, fonts } = typography;

createTheme('ssgTheme', {
  header: {
    fontSize: `${fontSizes[1]}rem`,
    fontFamily: `${fonts.sansSerif}`,
    fontColor: `${colors.white.base}`,
    backgroundColor: `${colors.secondary.lighter}`,
    height: '39px',
    fontWeight: '500'
    // fontColorActive: 'rgba(0,0,0,.87)',
  },
  button: {
    disabled: `${colors.secondary.lighter}`
  },
  contextMenu: {
    backgroundColor: '#e3f2fd',
    fontSize: '18px',
    fontColor: 'rgba(0,0,0,.87)',
    transitionTime: '225ms'
  },
  rows: {
    // default || spaced
    spacing: 'default',
    fontSize: `${fontSizes[1]}rem`,
    fontColor: `${colors.gray.darkest}`,
    backgroundColor: `${colors.white.base}`,
    borderWidth: '1px',
    borderColor: `${colors.gray.lighter}`,
    stripedColor: 'rgba(0,0,0,.03)',
    hoverFontColor: 'rgba(0,0,0,.87)',
    hoverBackgroundColor: `${colors.gray.lighter}`,
    height: '48px'
  },
  cells: {
    cellPadding: `48px`
  },
  expander: {
    fontColor: 'rgba(0,0,0,.87)',
    expanderColor: 'rgba(0,0,0,.54)',
    expanderColorDisabled: 'rgba(0,0,0,.12)',
    backgroundColor: 'transparent'
  }
});

const Table = ({
  data,
  columns,
  title,
  pagination,
  paginationServer,
  paginationTotalRows,
  paginationPerPage,
  paginationResetDefaultPage,
  onChangeRowsPerPage,
  onChangePage,
  onRowClicked,
  progressPending,
  progressCentered,
  defaultSortField,
  defaultSortAsc,
  noHeader,
  striped,
  pointerOnHover,
  highlightOnHover,
  className,
  selectableRows,
  selectableRowsComponent,
  selectableRowsNoSelectAll,
  clearSelectedRows,
  onSelectedRowsChange,
  hasDetailsView,
  overflowY,
  onSort,
  sortServer,
  sortFunction
}) => {
  const customStyles = {
    pagination: {
      style: {
        fontSize: `${fontSizes[0]}rem`,
        fontColor: `${colors.secondary.base}`
      },
      pageButtonsStyle: {
        '&:hover:not(:disabled)': {
          backgroundColor: `${colors.white.base}`
        }
      }
    }
  };
  return (
    <TableWrapper>
      <StyledDataTable
        title={title}
        className={className}
        clearSelectedRows={clearSelectedRows}
        columns={columns}
        theme="ssgTheme"
        customStyles={customStyles}
        data={data}
        defaultSortAsc={defaultSortAsc}
        defaultSortField={defaultSortField}
        hasDetailsView={hasDetailsView}
        highlightOnHover={highlightOnHover}
        noHeader={noHeader}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onRowClicked={onRowClicked}
        onSelectedRowsChange={onSelectedRowsChange}
        overflowY={overflowY}
        pagination={pagination}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        paginationPerPage={paginationPerPage}
        // Hide content related to rowsPerPage
        paginationComponentOptions={{
          rowsPerPageText: '',
          rangeSeparatorText: 'of'
        }}
        progressPending={progressPending}
        progressCentered={progressCentered}
        progressComponent={<Throbber isFullPage inTable />}
        paginationRowsPerPageOptions={[25, 50, 100, 500]}
        paginationIconFirstPage={<Chevron direction="left-end" />}
        paginationIconLastPage={<Chevron direction="right-end" />}
        paginationIconNext={<Chevron direction="right" />}
        paginationIconPrevious={<Chevron direction="left" />}
        paginationResetDefaultPage={paginationResetDefaultPage}
        pointerOnHover={pointerOnHover}
        responsive
        selectableRows={selectableRows}
        selectableRowsComponent={selectableRowsComponent}
        selectableRowsNoSelectAll={selectableRowsNoSelectAll}
        sortFunction={sortFunction}
        onSort={onSort}
        sortServer={sortServer}
        sortIcon={<SortIcon />}
        striped={striped}
      />
    </TableWrapper>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  striped: PropTypes.bool,
  pointerOnHover: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  title: PropTypes.string,
  noHeader: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationServer: PropTypes.bool,
  paginationPerPage: PropTypes.number,
  paginationTotalRows: PropTypes.number,
  paginationResetDefaultPage: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRowClicked: PropTypes.func,
  progressPending: PropTypes.bool,
  progressCentered: PropTypes.bool,
  defaultSortField: PropTypes.string,
  defaultSortAsc: PropTypes.bool,
  selectableRows: PropTypes.bool,
  selectableRowsComponent: PropTypes.func,
  selectableRowsNoSelectAll: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func,
  hasDetailsView: PropTypes.bool,
  overflowY: PropTypes.bool,
  sortServer: PropTypes.bool,
  sortFunction: PropTypes.func,
  onSort: PropTypes.func
};

Table.defaultProps = {
  title: '',
  pagination: true,
  noHeader: false,
  striped: false,
  pointerOnHover: false,
  highlightOnHover: false,
  paginationServer: false,
  paginationPerPage: 0,
  paginationTotalRows: 0,
  paginationResetDefaultPage: false,
  progressPending: false,
  progressCentered: true,
  defaultSortAsc: true,
  defaultSortField: 'created_at',
  selectableRows: false,
  selectableRowsComponent: () => {},
  selectableRowsNoSelectAll: false,
  clearSelectedRows: false,
  onSelectedRowsChange: () => {},
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  onRowClicked: () => {},
  hasDetailsView: false,
  overflowY: false,
  sortServer: false,
  sortFunction: (rows, field, dir) => {
    const newRows = rows
      .map(row => row)
      .sort((rowA, rowB) => {
        let valA = rowA[field].toLowerCase();
        let valB = rowB[field].toLowerCase();
        if (field === 'phone') {
          valA = formatPhoneNumber(rowA[field]);
          if (valA) {
            valA = valA.number;
          }
          valB = formatPhoneNumber(rowB[field]);
          if (valB) {
            valB = valB.number;
          }
        }
        if (dir === 'asc') {
          if (valA > valB) {
            return 1;
          }
          return -1;
        }
        if (valA > valB) {
          return -1;
        }
        return 1;
      });
    return newRows;
  },
  onSort: () => {}
};

const TableWrapper = styled.div`
  .rdt_Pagination {
    div > svg {
      right: -0.5rem;
    }
    button {
      &:hover:not(:disabled) {
        svg g g {
          stroke: ${colors.secondary.lightest};
        }
      }
      &:disabled {
        svg g g {
          stroke: ${colors.gray.lighter};
        }
      }
    }
  }
`;

const StyledDataTable = styled(DataTable)`
  min-height: 100px;
  position: static;
  padding: ${space[2]}rem 0;
  margin-bottom: 0;
  .rdt_Table {
    height: unset;
  }
  ${'' /* Provide padding to progressComponent's wrapper [read: obscured] so throbber is fully visible */}
  > div > div:first-child {
    top: ${space[2]}rem;
  }
  .spinner-border {
    z-index: 1;
  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: ${colors.secondary.lighter};
  }
  .rdt_TableCol {
    .rdt_TableCol_Sortable {
      div:first-child {
        color: ${colors.white.base};
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .__rdt_custom_sort_icon__ {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
        }
        &.asc svg {
          transform: rotate(0deg) translateY(-50%);
        }
        &.desc svg {
          transform: rotate(180deg) translateY(40%);
        }
      }
    }
  }
  .rdt_TableRow {
    border-top: 1px solid ${colors.gray.lighter};
    border-bottom: none;
    border-right: 1px solid ${colors.white.base};
    border-left: 1px solid ${colors.white.base};
    &:hover {
      background-color: ${props =>
        props.highlightOnHover ? colors.gray.lightest : 'none'};
      &:first-child {
        border-top: 1px solid ${colors.white.base};
      }
    }
    .rdt_TableCell {
      font-size: 1rem;
    }
    .rdt_TableCell:last-child {
      ${props =>
        props.hasDetailsView &&
        css`
          padding-right: unset;
          display: flex;
          justify-content: center;
          .gQcVmz {
            display: flex;
        `}
    }
  }
`;

export default Table;
