import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, breakpoints, space, headerHeight, sidebarWidth, typography } from 'Theme';
import { NavLink } from 'react-router-dom';
import Logo from 'Assets/images/Logo';
import {
  TicketsIcon,
  DealersIcon,
  AccountIcon,
  ReportsIcon,
  ServiceCentersIcon,
  UsersIcon
} from 'Assets/images/icons';
import { useTranslation } from 'react-i18next';

const isActiveFunc = (match, location, str) => {
  if (location.pathname.includes(str)) {
    return true;
  }
  if (!match) {
    return false;
  }
  return true;
};

const Sidebar = ({ isHidden, userLevel, userDealerId, handleNavEventFunc }) => {
  const { t } = useTranslation();
  const getTrimmedVersion = () => {
    const fullVersion = process.env.VERSION;
    // If there is no version, return "dev"
    if (!fullVersion) return "dev"
    // The version is expected to be a commit hash, and we don't need the whole thing
    if (fullVersion.length > 8) {
      return fullVersion.slice(fullVersion.length - 8, fullVersion.length)
    }
    // But if it isn't at least 8 characters, return the entire version
    return fullVersion;
  }
  
  return (
    <SidebarWrapper isHidden={isHidden}>
      <LogoWrapper>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </LogoWrapper>
      <Content>
        <NavItem
          isActive={(match, location) =>
            isActiveFunc(match, location, 'ticket')
          }
          exact
          to="/tickets"
          activeClassName="active"
          label="tickets"
          onClick={() => handleNavEventFunc()}
        >
          <TicketsIcon />
          <NavLabel>{t('sidebar.tickets')}</NavLabel>
        </NavItem>
        {userLevel === 'super_admin' && (
          <NavItem
            isActive={(match, location) =>
              isActiveFunc(match, location, 'service_center')
            }
            to="/service-centers"
            activeClassName="active"
            onClick={() => handleNavEventFunc()}
          >
            <ServiceCentersIcon />
            <NavLabel>{t('sidebar.service_centers')}</NavLabel>
          </NavItem>
        )}
        {userLevel === 'service_center_user' && (
          <NavItem
            isActive={(match, location) =>
              isActiveFunc(match, location, 'dealership')
            }
            to="/dealerships"
            activeClassName="active"
            onClick={() => handleNavEventFunc()}
          >
            <DealersIcon />
            <NavLabel>{t('sidebar.dealers')}</NavLabel>
          </NavItem>
        )}
        {userLevel === 'service_center_user' && (
          <Fragment>
            <NavItem
              isActive={(match, location) =>
                isActiveFunc(match, location, 'users')
              }
              to="/users"
              activeClassName="active"
              onClick={() => handleNavEventFunc()}
            >
              <UsersIcon />
              <NavLabel>{t('sidebar.users')}</NavLabel>
            </NavItem>
            <NavItem
              isActive={(match, location) =>
                isActiveFunc(match, location, 'reports')
              }
              to="/reports"
              activeClassName="active"
              onClick={() => handleNavEventFunc()}
            >
              <ReportsIcon />
              <NavLabel>{t('sidebar.reports')}</NavLabel>
            </NavItem>
          </Fragment>
        )}
        {userLevel === 'dealer_user' && (
          <NavItem
            isActive={(match, location) =>
              isActiveFunc(match, location, 'dealership')
            }
            to={`/dealership/${userDealerId}`}
            activeClassName="active"
            onClick={() => handleNavEventFunc()}
          >
            <DealersIcon />
            <NavLabel>{t('sidebar.dealer')}</NavLabel>
          </NavItem>
        )}
        <NavItem
          exact
          to="/account"
          activeClassName="active"
          onClick={() => handleNavEventFunc()}
        >
          <AccountIcon />
          <NavLabel>{t('sidebar.acct')}</NavLabel>
        </NavItem>
        <Version>{getTrimmedVersion()}</Version>
      </Content>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  userLevel: PropTypes.string.isRequired,
  userDealerId: PropTypes.number,
  handleNavEventFunc: PropTypes.func.isRequired
};

Sidebar.defaultProps = {
  userDealerId: 0
};

const SidebarWrapper = styled.div`
  position: ${props => (props.isHidden ? 'absolute' : 'fixed')};
  top: 0;
  left: ${props => (props.isHidden ? `-${sidebarWidth}px` : 0)};
  min-width: ${sidebarWidth}px;
  max-width: ${sidebarWidth}px;
  height: 100vh;
  background-color: ${colors.white.base};
  z-index: 16;
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: ${breakpoints.lg}) {
    position: fixed;
    left: 0;
  }
`;

const LogoWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${headerHeight}px;
  width: 100%;
  background-color: ${colors.white.base};
  z-index: 5;
  a {
    line-height: 1;
    svg {
      width: 140px;
    }
  }
`;

const Content = styled.div`
  height: calc(100vh - ${headerHeight}px);
  display: flex;
  flex-direction: column;
`;

const NavItem = styled(NavLink)`
  position: relative;
  display: flex;
  padding: ${space[2]}rem;
  color: ${colors.black.base};
  background-color: ${colors.white.base};
  border: 1px solid ${colors.white.base};
  font-stretch: condensed;
  font-style: oblique;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 5;
  transition: all 0.3s ease;
  svg {
    margin-right: ${space[2]}rem;
  }
  &:hover {
    background-color: ${colors.gray.lighter};
    border-right: 1px solid ${colors.white.base};
  }
  &.active {
    color: ${colors.primary.darkest};
    &:not(.toggle) {
      background-color: ${colors.gray.lighter};
      border-right: 1px solid ${colors.white.base};
    }
    svg path {
      fill: ${colors.primary.base};
    }
  }
`;

const NavLabel = styled.span`
  > * {
    width: min-content;
  }
`;

const Version = styled.p`
  margin-top: auto;
  color: ${colors.gray.base};
  font-size: ${typography.fontSizes[0]}rem;
  text-align: center;
  padding: 4px;
`;

export default Sidebar;
