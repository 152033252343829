import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Login,
  SignUp,
  AccountSetup,
  ForgotPassword,
  ResetPassword,
  Public,
  Private,
  Ticket,
  TicketsList,
  Dealership,
  DealershipList,
  ServiceCenterList,
  User,
  UserList,
  DealershipInvitation,
  MyAccount,
  ReportingDashboard
} from "Containers";

export default () => (
  <Switch>
    <Route
      exact
      path="/login"
      render={() => (
        <Public>
          <Login />
        </Public>
      )}
    />
    <Route
      exact
      path="/logout"
      render={route => {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: "/logout" },
              search: route.location.search
            }}
          />
        );
      }}
    />
    <Route
      exact
      path="/signup"
      render={() => (
        <Public>
          <SignUp />
        </Public>
      )}
    />
    <Route
      exact
      path="/user/verify"
      render={() => (
        <Public>
          <AccountSetup />
        </Public>
      )}
    />
    <Route
      exact
      path="/forgotpassword"
      render={() => (
        <Public>
          <ForgotPassword />
        </Public>
      )}
    />
    <Route
      exact
      path="/passwordreset"
      render={() => (
        <Public>
          <ResetPassword />
        </Public>
      )}
    />
    <ProtectedRoute exact strict path="/">
      <Private>
        <Redirect to={{ pathname: "/tickets", state: { from: "/" } }} />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/tickets">
      <Private>
        <TicketsList />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/reports">
      <Private>
        <ReportingDashboard />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/ticket/:ticket">
      <Private>
        <Ticket />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact strict path="/service-center">
      <Private>
        <Redirect
          to={{
            pathname: "/service-center/techs",
            state: { from: "/service-center" }
          }}
        />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/service-center/techs">
      <Private>Techs Here</Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/service-center/settings">
      <Private>Settings Here</Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/dealerships">
      <Private>
        <DealershipList />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/dealership/:dealership">
      <Private>
        <Dealership />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/dealership/join">
      <Private>
        <DealershipInvitation />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/service-centers">
      <Private>
        <ServiceCenterList />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/account">
      <Private>
        <MyAccount />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact strict path="/dealership/:dealership/join">
      <Private>
        <Redirect to={{ pathname: "/tickets", state: { from: "/" } }} />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/users">
      <Private>
        <UserList />
      </Private>
    </ProtectedRoute>
    <ProtectedRoute exact path="/user/:userId">
      <Private>
        <User />
      </Private>
    </ProtectedRoute>
  </Switch>
);

const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        localStorage.getItem("user") ? (
          <AppWrapper>{children}</AppWrapper>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const AppWrapper = styled.div`
  overflow-x: hidden;
`;

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape()
};

ProtectedRoute.defaultProps = {
  location: {}
};
