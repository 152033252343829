export default {
  en: {
    form: {
      required: "Required",
      required_feedback: "This field is required",
      password_match_feedback: "Password confirmation must match password",
      filter: "Filter",
      valid: {
        email_address: "Valid email address",
        passwords_match: "Passwords match"
      },
      invalid: {
        email_address: "Please provide a valid email address",
        passwords_match: "Password confirmation must match password",
        password_criteria: "Does not meet the security criteria",
        phone_number: "Please provide a valid phone number"
      },
      labels: {
        email: "Email",
        region: "Region",
        country_code: "Country\u00A0Code",
        phone: "Phone Number",
        first_name: "First Name",
        last_name: "Last Name",
        name: "Name",
        street_address: "Street Address",
        address_two: "Address Line 2",
        city: "City/Town",
        state: "State/Province",
        zip: "Postal Code",
        country: "Country",
        location: "Location Note/Name",
        preferred_language: "Preferred Language",
        status: "Status",
        level: "Level",
        company: "Company Name",
        company_email: "Company Email",
        company_street_address: "Company Street Address",
        company_address_two: "Company Address Line 2",
        company_city: "Company City/Town",
        company_state: "Company State/Province",
        company_zip: "Company Postal Code",
        company_country: "Company Country",
        password: "Password",
        newsletter: "Subscribe to newsletter",
        terms: "I agree to the terms and conditions",
        terms1: "I agree to the ",
        terms2: "terms and conditions",
        password_confirm: "Confirm Password",
        old_pass: "Old Password",
        new_pass: "New Password",
        lang_pref: "Preferred Language",
        primary_location: "Primary Location",
        filters: "Filters",
        search: "Search",
        start_date: "Start Date",
        end_date: "End Date",
        user_name: "User Name",
        user_email: "User Email"
      },
      placeholders: {
        first_name: "Fox",
        last_name: "Mulder",
        email: "fox@mulderinvestigators.io",
        password: "Password",
        password_confirm: "Password Confirmation",
        address1: "624 E Burnside St.",
        address2: "Apt. 1",
        city: "Portland",
        state: "Oregon",
        zip: "97214-1217",
        location: "Westside Store",
        company: {
          name: "Abe's Bikes",
          address1: "926 W Burnside St.",
          address2: "Ste. 204",
          city: "Portland",
          state: "Oregon",
          zip: "97217-1102",
          phone: "(503) 123-4567"
        },
        datepicker: "mm/dd/yyyy",
        loading: "Loading...",
        select: "Select...",
        search: "Search..."
      },
      helper_text: {
        company_email: "The main contact email of your dealership",
        primary_location: "The main location for your dealership",
        company_phone: "The main phone number of your dealership",
        password:
          "Must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number"
      },
      filters: {
        active: "Active",
        inactive: "Inactive"
      }
    }
  },
  de: {
    form: {
      required: "Erforderlich",
      required_feedback: "Dieses Feld wird benötigt",
      password_match_feedback:
        "Die Passwortbestätigung muss mit dem Passwort übereinstimmen",
      filter: "Filter",
      valid: {
        email_address: "Gültige Email Addresse",
        passwords_match: "Passwörter stimmen überein"
      },
      invalid: {
        email_address: "bitte geben Sie eine gültige E-Mail Adresse an",
        passwords_match:
          "Die Passwortbestätigung muss mit dem Passwort übereinstimmen",
        password_criteria: "Erfüllt nicht die Sicherheitskriterien",
        phone_number: "geben Sie bitte eine gültige Telephonnummer an"
      },
      labels: {
        email: "Email",
        region: "Bereich",
        country_code: "Country\u00A0Code",
        phone: "Telefonnummer",
        first_name: "Vorname",
        last_name: "Familienname, Nachname",
        name: "Name",
        street_address: "Adresse",
        address_two: "Adresszeile 2",
        city: "Stadt/Gemeinde",
        state: "Staat/Provinz",
        zip: "Postleitzahl",
        country: "Land",
        location: "Ortsanmerkung/Name",
        preferred_language: "Bevorzugte Sprache",
        status: "Status",
        level: "Stufe",
        company: "Name der Firma",
        company_email: "Firmen-E-Mail",
        company_street_address: "Straße des Unternehmens",
        company_address_two: "Firmenadresse Zeile 2",
        company_city: "Stadt/Stadt des Unternehmens",
        company_state: "Staat/Provinz des Unternehmens",
        company_zip: "Postleitzahl des Unternehmens",
        company_country: "Firmenland",
        password: "Passwort",
        newsletter: "Newsletter abonnieren",
        terms: "Ich stimme den AGB zu",
        terms1: "Ich stimme ",
        terms2: "den AGB zu",
        password_confirm: "Bestätige das Passwort",
        old_pass: "Altes Passwort",
        new_pass: "Neues Kennwort",
        lang_pref: "Bevorzugte Sprache",
        primary_location: "Primärer Standort",
        filters: "Filters",
        search: "Suche",
        start_date: "Startdatum",
        end_date: "Endtermin"
      },
      placeholders: {
        first_name: "Fox",
        last_name: "Mulder",
        email: "fox@mulderinvestigators.io",
        password: "Passwort",
        password_confirm: "Passwort Bestätigung",
        address1: "624 E Burnside St.",
        address2: "Apt. 1",
        city: "Portland",
        state: "Oregon",
        zip: "97214-1217",
        location: "Westside Store",
        company: {
          name: "Abe's Bikes",
          address1: "926 W Burnside St.",
          address2: "Ste. 204",
          city: "Portland",
          state: "Oregon",
          zip: "97217-1102",
          phone: "(503) 123-4567"
        },
        datepicker: "mm/dd/yyyy",
        loading: "Wird geladen...",
        select: "Auswählen...",
        search: "Suche..."
      },
      helper_text: {
        company_email: "Die Hauptkontakt-E-Mail Ihres Händlers",
        primary_location: "Der Hauptstandort für Ihr Autohaus",
        company_phone: "Die Haupttelefonnummer Ihres Händlers",
        password:
          "Muss mindestens 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten"
      },
      filters: {
        active: "Aktiv",
        inactive: "Inaktiv"
      }
    }
  },
  jp: {
    form: {
      required: "必須",
      required_feedback: "この項目は必須です",
      password_match_feedback:
        "パスワードの確認はパスワードと一致する必要があります",
      filter: "フィルター",
      valid: {
        email_address: "有効な電子メールアドレス",
        passwords_match: "パスワードが一致する"
      },
      invalid: {
        email_address: "有効なメールアドレスを入力してください",
        passwords_match: "パスワードの確認はパスワードと一致する必要があります",
        password_criteria: "セキュリティ基準を満たしていません",
        phone_number: "有効な電話番号を入力してください"
      },
      labels: {
        email: "Eメール",
        region: "地域",
        country_code: "国コード",
        phone: "電話番号",
        first_name: "ファーストネーム",
        last_name: "苗字",
        name: "名前",
        street_address: "住所",
        address_two: "住所2行この住所",
        city: "市/町",
        state: "州/県",
        zip: "郵便番号",
        country: "国",
        location: "場所メモ/名前",
        preferred_language: "優先言語",
        status: "スターテス",
        level: "レベル",
        company: "会社名",
        company_email: "会社の電子メール",
        company_street_address: "会社の住所",
        company_address_two: "会社の住所2行目",
        company_city: "会社の市/町",
        company_state: "会社の州/県",
        company_zip: "会社の郵便番号",
        company_country: "会社の国",
        password: "パスワード",
        newsletter: "ニュースレターを購読します",
        terms: "私は利用規約に同意します",
        terms1: "同意します",
        terms2: "利用規約に",
        password_confirm: "パスワードを認証する",
        old_pass: "以前のパスワード",
        new_pass: "新しいパスワード",
        lang_pref: "優先言語",
        primary_location: "主な場所",
        filters: "フィルタ",
        search: "検索",
        start_date: "開始日",
        end_date: "終了日"
      },
      placeholders: {
        first_name: "Fox",
        last_name: "Mulder",
        email: "fox@mulderinvestigators.io",
        password: "Password",
        password_confirm: "Password Confirmation",
        address1: "624 E Burnside St.",
        address2: "Apt. 1",
        city: "Portland",
        state: "Oregon",
        zip: "97214-1217",
        location: "Westside Store",
        company: {
          name: "Abe's Bikes",
          address1: "926 W Burnside St.",
          address2: "Ste. 204",
          city: "Portland",
          state: "Oregon",
          zip: "97217-1102",
          phone: "(503) 123-4567"
        },
        datepicker: "mm/dd/yyyy",
        loading: "読み込み中...",
        select: "選択する...",
        search: "検索..."
      },
      helper_text: {
        company_email: "販売店の主な連絡先メールアドレス",
        primary_location: "ディーラーの主な場所",
        company_phone: "販売店のメインの電話番号",
        password:
          "少なくとも8文字、1つの大文字、1つの小文字、および1つの数字が含まれている必要があります"
      },
      filters: {
        active: "アクティブ",
        inactive: "非活性"
      }
    }
  },
  it: {
    form: {
      required: "Necessario",
      required_feedback: "Questo campo è obbligatorio",
      password_match_feedback:
        "La conferma della password deve corrispondere alla password",
      filter: "Filtro",
      valid: {
        email_address: "Indirizzo e-mail valido",
        passwords_match: "la parola d'ordine coincide"
      },
      invalid: {
        email_address: "Per favore fornisci un indirizzo email valido",
        passwords_match:
          "La conferma della password deve corrispondere alla password",
        password_criteria: "Non soddisfa i criteri di sicurezza",
        phone_number: "Si prega di fornire un numero di telefono valido"
      },
      labels: {
        email: "Email",
        region: "regione",
        country_code: "Paese\u00A0Codice",
        phone: "Numero di telefono",
        first_name: "Nome di battesimo",
        last_name: "Cognome",
        name: "Nome",
        street_address: "Indirizzo",
        address_two: "Indirizzo 2",
        city: "Città/Città",
        state: "Stato/Provincia",
        zip: "codice postale",
        country: "Paese",
        location: "Posizione Nota/Nome",
        preferred_language: "Lingua preferita",
        status: "Stato",
        level: "Livello",
        company: "Nome della ditta",
        company_email: "E-mail aziendale",
        company_street_address: "Indirizzo della società",
        company_address_two: "Indirizzo della società Riga 2",
        company_city: "Azienda Città/Città",
        company_state: "Società Stato/Provincia",
        company_zip: "Codice postale dell'azienda",
        company_country: "Paese dell'azienda",
        password: "Parola d'ordine",
        newsletter: "Iscriviti alla Newsletter",
        terms: "Accetto Termini e Condizioni",
        terms1: "Accetto ",
        terms2: "Termini e Condizioni",
        password_confirm: "Conferma password",
        old_pass: "vecchia password",
        new_pass: "nuova password",
        lang_pref: "Lingua preferita",
        primary_location: "Posizione primaria",
        filters: "Filtri",
        search: "Ricerca",
        start_date: "Data d'inizio",
        end_date: "Data di fine"
      },
      placeholders: {
        first_name: "Fox",
        last_name: "Mulder",
        email: "fox@mulderinvestigators.io",
        password: "Password",
        password_confirm: "Password Confirmation",
        address1: "624 E Burnside St.",
        address2: "Apt. 1",
        city: "Portland",
        state: "Oregon",
        zip: "97214-1217",
        location: "Westside Store",
        company: {
          name: "Abe's Bikes",
          address1: "926 W Burnside St.",
          address2: "Ste. 204",
          city: "Portland",
          state: "Oregon",
          zip: "97217-1102",
          phone: "(503) 123-4567"
        },
        datepicker: "mm/dd/yyyy",
        loading: "Caricamento in corso...",
        select: "Selezionare...",
        search: "Ricerca..."
      },
      helper_text: {
        company_email:
          "L'e-mail di contatto principale della tua concessionaria",
        primary_location: "La sede principale per la tua concessionaria",
        company_phone:
          "Il numero di telefono principale della tua concessionaria",
        password:
          "Deve contenere almeno 8 caratteri, 1 lettera maiuscola, 1 lettera minuscola e 1 numero"
      },
      filters: {
        active: "Attivo",
        inactive: "Inattivo"
      }
    }
  }
};
