import React from "react";
import styled from "styled-components";
import { DropdownItem } from "@bootstrap-styled/v4";
import Dropdown from "Components/Dropdown";
import { OverflowIcon } from "Assets/images/icons";
import { space } from "Theme";

const TechKebab = props => {
  const { onClickArchive, disabled = false } = props;
  const actionOptions = [
    {
      tag: "button",
      text: "Archive",
      onClick: onClickArchive,
      disabled
    }
  ];

  return (
    <StyledDropdown toggle={<OverflowIcon />}>
      {actionOptions.map((option, index) => (
        <DropdownItem
          key={index}
          tag={option.tag}
          onClick={() => {
            option.disabled ? null : option.onClick();
          }}
          disabled={option.disabled ?? false}
        >
          {option.text}
        </DropdownItem>
      ))}
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  width: ${space[1] * 5}rem;
  height: ${space[1] * 5}rem;
  .dropdown-menu {
    top: 70%;
  }
  .dropdown-item {
    text-decoration: none;
    font-size: 1rem;
  }
`;

export default TechKebab;
