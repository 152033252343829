import { api, retryApiCall } from '../api';
import {
  FETCH_PART_BY_SERIAL_LOADING,
  FETCH_PART_BY_SERIAL_ERROR,
  FETCH_PART_BY_SERIAL_SUCCESS,
  FETCH_PART_TYPES_LOADING,
  FETCH_PART_TYPES_ERROR,
  FETCH_PART_TYPES_SUCCESS,
  FETCH_PART_MODEL_ATTRIBUTES_LOADING,
  FETCH_PART_MODEL_ATTRIBUTES_ERROR,
  FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  RESET_PART_MODEL_ATTRIBUTES,
  FETCH_PART_TYPE_ATTRIBUTES_LOADING,
  FETCH_PART_TYPE_ATTRIBUTES_ERROR,
  FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  RESET_PART_TYPE_ATTRIBUTES,
  FETCH_MODEL_DIAGNOSES_LOADING,
  FETCH_MODEL_DIAGNOSES_ERROR,
  FETCH_MODEL_DIAGNOSES_SUCCESS,
  RESET_DIAGNOSES,
  FETCH_PART_TYPE_DIAGNOSES_LOADING,
  FETCH_PART_TYPE_DIAGNOSES_ERROR,
  FETCH_PART_TYPE_DIAGNOSES_SUCCESS
} from './types';

export const partCallTypes = {};
const apiCall = retryApiCall.use(partCallTypes);

/**
 * Fetch All Part Types
 */
export const fetchPartTypesLoading = data => ({
  type: FETCH_PART_TYPES_LOADING,
  payload: data
});

export const fetchPartTypesFailure = data => ({
  type: FETCH_PART_TYPES_ERROR,
  payload: data
});

export const fetchPartTypesSuccess = data => ({
  type: FETCH_PART_TYPES_SUCCESS,
  payload: data
});

export const fetchPartTypes = () => dispatch => {
  dispatch(apiCall(fetchPartTypesLoading, fetchPartTypes, {}));
  return api
    .get(`/parts/types`)
    .then(res => dispatch(fetchPartTypesSuccess(res.data.data)))
    .catch(err => dispatch(fetchPartTypesFailure(err)));
};

/**
 * Fetch PartModelAttributes
 */
export const fetchPartModelAttributesLoading = data => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_LOADING,
  payload: data
});

export const fetchPartModelAttributesFailure = data => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_ERROR,
  payload: data
});

export const fetchPartModelAttributesSuccess = data => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  payload: data
});

export const fetchPartModelAttributes = data => dispatch => {
  dispatch(
    apiCall(fetchPartModelAttributesLoading, fetchPartModelAttributes, data)
  );
  return api
    .get(`/parts/model/${data.modelId}/attributes`)
    .then(res => dispatch(fetchPartModelAttributesSuccess(res.data)))
    .catch(err => dispatch(fetchPartModelAttributesFailure(err)));
};

export const resetPartModelAttributes = () => ({
  type: RESET_PART_MODEL_ATTRIBUTES
});

/**
 * Fetch PartTypeAttributes
 */
export const fetchPartTypeAttributesLoading = data => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_LOADING,
  payload: data
});

export const fetchPartTypeAttributesError = data => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_ERROR,
  payload: data
});

export const fetchPartTypeAttributesSuccess = data => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  payload: data
});

export const fetchPartTypeAttributes = data => dispatch => {
  dispatch(
    apiCall(fetchPartTypeAttributesLoading, fetchPartTypeAttributes, data)
  );
  return api
    .get(`/parts/type/${data.typeId}/attributes`)
    .then(res => dispatch(fetchPartTypeAttributesSuccess(res.data)))
    .catch(err => dispatch(fetchPartTypeAttributesError(err)));
};

export const resetPartTypeAttributes = () => ({
  type: RESET_PART_TYPE_ATTRIBUTES
});

/**
 * Fetch Diagnoses based on Part Type
 */
export const fetchPartTypeDiagnosesLoading = data => ({
  type: FETCH_PART_TYPE_DIAGNOSES_LOADING,
  payload: data
});

export const fetchPartTypeDiagnosesFailure = data => ({
  type: FETCH_PART_TYPE_DIAGNOSES_ERROR,
  payload: data
});

export const fetchPartTypeDiagnosesSuccess = data => ({
  type: FETCH_PART_TYPE_DIAGNOSES_SUCCESS,
  payload: data
});

export const fetchPartTypeDiagnoses = data => dispatch => {
  dispatch(
    apiCall(fetchPartTypeDiagnosesLoading, fetchPartTypeDiagnoses, data)
  );
  return api
    .get(`/parts/type/${data.partTypeId}/diagnoses`)
    .then(res => dispatch(fetchPartTypeDiagnosesSuccess(res.data)))
    .catch(err => dispatch(fetchPartTypeDiagnosesFailure(err)));
};

/**
 * Fetch Diagnoses based on Part Model
 */
export const fetchModelDiagnosesLoading = data => ({
  type: FETCH_MODEL_DIAGNOSES_LOADING,
  payload: data
});

export const fetchModelDiagnosesFailure = data => ({
  type: FETCH_MODEL_DIAGNOSES_ERROR,
  payload: data
});

export const fetchModelDiagnosesSuccess = data => ({
  type: FETCH_MODEL_DIAGNOSES_SUCCESS,
  payload: data
});

export const fetchModelDiagnoses = data => dispatch => {
  dispatch(apiCall(fetchModelDiagnosesLoading, fetchModelDiagnoses, data));
  return api
    .get(`/parts/model/${data.modelId}/diagnoses`)
    .then(res => dispatch(fetchModelDiagnosesSuccess(res.data)))
    .catch(err => dispatch(fetchModelDiagnosesFailure(err)));
};

export const resetDiagnoses = () => ({
  type: RESET_DIAGNOSES
});

/**
 * Fetch Part by Serial Number
 */
export const fetchPartBySerialLoading = data => ({
  type: FETCH_PART_BY_SERIAL_LOADING,
  payload: data
});

export const fetchPartBySerialSuccess = data => ({
  type: FETCH_PART_BY_SERIAL_SUCCESS,
  payload: data
});

export const fetchPartBySerialFailure = data => ({
  type: FETCH_PART_BY_SERIAL_ERROR,
  payload: data
});

export const fetchPartBySerialNumber = serialNumber => dispatch => {
  dispatch(
    apiCall(fetchPartBySerialLoading, fetchPartBySerialNumber, serialNumber)
  );
  return api
    .get(`/part/${serialNumber}`)
    .then(res => dispatch(fetchPartBySerialSuccess(res.data)))
    .catch(err => dispatch(fetchPartBySerialFailure(err.response.status)));
};
