import LoginLang from "./login";
import SignUpLang from "./signup";
import ForgotPasswordLang from "./forgotpassword";
import PasswordResetLang from "./resetpassword";
import AccountSetupLang from "./accountsetup";
import CreateDealerLocationLang from "./createdealerlocation";
import CreateDealerUserLang from "./createdealeruser";
import DataTableLang from "./datatable";
import DealershipListLang from "./dealershiplist";
import DealershipLang from "./dealership";
import FormLang from "./form";
import DealershipLocationsLang from "./dealershiplocations";
import DealershipUsersLang from "./dealershipusers";
import TicketList from "./ticketlist";
import Countries from "./countries";
import Languages from "./languages";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import Modal from "./modal";
import CreateTicket from "./createticket";
import Ticket from "./ticket";
import MyAccount from "./myaccount";
import ReportingDashboard from "./reportingdashboard";
import DealershipInvitation from "./dealershipinvitation";
import Miscellaneous from "./misc";
import User from "./user";

const supportedLanguages = [
  "en",
  "es",
  "fr",
  "nl",
  "zh",
  "de",
  "jp",
  "it",
  "es-MX"
];
const registeredPages = [
  LoginLang,
  FormLang,
  SignUpLang,
  ForgotPasswordLang,
  PasswordResetLang,
  AccountSetupLang,
  DealershipLocationsLang,
  DealershipUsersLang,
  CreateDealerLocationLang,
  CreateDealerUserLang,
  DealershipListLang,
  DealershipLang,
  DataTableLang,
  TicketList,
  Countries,
  Languages,
  Header,
  Footer,
  Sidebar,
  Modal,
  Ticket,
  CreateTicket,
  MyAccount,
  ReportingDashboard,
  DealershipInvitation,
  Miscellaneous,
  User
];

const lang = {};

const initializeSupportedLanguages = () => {
  for (const language of supportedLanguages) {
    lang[language] = {
      translation: {}
    };
  }
};

const registerPages = () => {
  for (const page of registeredPages) {
    for (const language of supportedLanguages) {
      lang[language].translation = {
        ...lang[language].translation,
        ...page[language]
      };
    }
  }
};

initializeSupportedLanguages();
registerPages();
export default lang;
