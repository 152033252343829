import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Heading,
  Paragraph,
  Form,
  Input as SsgInput,
  Button,
  Alert,
  Checkbox,
  RadioGroup,
  Radio
} from "Components";
import { space } from "Theme";
import { FormGroup, Option } from "@bootstrap-styled/v4";
import { acceptTechInvite } from "Actions/serviceCenter";
import { login } from "Actions/user";

const InviteAccept = props => {
  const [form, setForm] = useState({
    email: "",
    language: "en",
    firstname: "",
    lastname: "",
    password: "",
    passwordconfirmation: "",
    terms: false
  });
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { t } = props;

  const handleChange = event => {
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }
    const updatedForm = {
      ...form,
      [event.target.name]: newValue
    };
    setForm(updatedForm);
  };

  const genericRequiredRule = {
    rule: "required",
    messages: { error: t("form.required_feedback"), success: t("required") }
  };

  const passwordRule = {
    rule: "password",
    messages: {
      error: t("form.invalid.password_criteria"),
      success: ""
    }
  };
  const getUrlParam = param => {
    const url = window.location.href;
    const urlO = new URL(url);
    const params = new URLSearchParams(urlO.search);
    return params.get(param);
  };

  const onSubmit = () => {
    const { acceptTechInvite, login } = props;
    // Get the invite token from the url
    const inviteToken = getUrlParam("invite_token");
    const userData = form;
    const data = {
      token: inviteToken,
      user: userData
    };
    setFetching(true);
    acceptTechInvite(data)
      .catch(err => console.error(err))
      .finally(() => {
        setFetching(false);
        history.push("/");
      });
  };

  const {
    user: { isLoggedIn },
    history
  } = props;
  // Logged in users shouldn't be accepting invitations
  if (isLoggedIn) {
    history.push("/login");
  }

  return (
    <FormContainer>
      <Form
        onSubmitFunc={onSubmit}
        validation={{
          firstname: [genericRequiredRule],
          lastname: [genericRequiredRule],
          language: [genericRequiredRule],
          password: [genericRequiredRule, passwordRule],
          passwordconfirmation: [
            genericRequiredRule,
            {
              rule: "matches",
              fieldToMatch: "password",
              messages: {
                error: t("form.invalid.passwords_match"),
                success: t("form.valid.passwords_match")
              }
            }
          ],
          terms: [genericRequiredRule]
        }}
        render={status => (
          <Fragment>
            <FormGroup>
              <Heading size={1}>{t("account_setup.heading_main")}</Heading>
              <Paragraph>{t("account_setup.heading_sub")}</Paragraph>
            </FormGroup>
            <FormGroup>
              <SsgInput
                label={t("form.labels.first_name")}
                type="text"
                name="firstname"
                value={form.firstname}
                onChangeFunc={handleChange}
                status={status.firstname.status}
                feedbackMsg={status.firstname.feedback}
                inputId="first-name"
                placeholder="John"
                isRequired
                width="100%"
              />
            </FormGroup>
            <FormGroup>
              <SsgInput
                label={t("form.labels.last_name")}
                type="text"
                name="lastname"
                value={form.lastname}
                onChangeFunc={handleChange}
                status={status.lastname.status}
                feedbackMsg={status.lastname.feedback}
                inputId="last-name"
                placeholder="Smith"
                isRequired
                width="100%"
              />
            </FormGroup>
            <FormGroup>
              <SsgInput
                label={t("form.labels.preferred_language")}
                inputId="language"
                onChangeFunc={handleChange}
                type="select"
                value={form.language}
                status={status.language.status}
                feedbackMsg={status.language.feedback}
                isRequired
                name="language"
                width="100%"
              >
                <Option value="en">{t("languages.en")}</Option>
                <Option value="de">{t("languages.de")}</Option>
                <Option value="es">{t("languages.es")}</Option>
                <Option value="fr">{t("languages.fr")}</Option>
                <Option value="it">{t("languages.it")}</Option>
                <Option value="jp">{t("languages.jp")}</Option>
                <Option value="nl">{t("languages.nl")}</Option>
                <Option value="zh">{t("languages.zh")}</Option>
              </SsgInput>
            </FormGroup>
            <FormGroup>
              <SsgInput
                label={t("form.labels.password")}
                type="password"
                name="password"
                value={form.password}
                onChangeFunc={handleChange}
                status={status.password.status}
                feedbackMsg={status.password.feedback}
                inputId="password"
                placeholder="Password"
                isRequired
                width="100%"
                helperText={t("form.helper_text.password")}
              />
            </FormGroup>
            <FormGroup>
              <SsgInput
                label={t("form.labels.password_confirm")}
                type="password"
                name="passwordconfirmation"
                value={form.passwordconfirmation}
                onChangeFunc={handleChange}
                status={status.passwordconfirmation.status}
                feedbackMsg={status.passwordconfirmation.feedback}
                inputId="password-confirmation"
                placeholder="Password Confirmation"
                isRequired
                width="100%"
              />
            </FormGroup>
            <FormGroup>
              <div style={{ display: "flex" }}>
                <Checkbox
                  label={t("form.labels.terms1")}
                  inputId="terms-conditions"
                  name="terms"
                  onChangeFunc={handleChange}
                  value={form.terms}
                  status={status.terms.status}
                  feedbackMsg={status.terms.feedback}
                />
                &nbsp;
                <a
                  href="https://www.srsuntour.com/footer/c-2019-sr-suntour-all-rights-reserved/terms-of-use/"
                  target="_blank"
                >
                  <small>{t("form.labels.terms2")}</small>
                </a>
              </div>
            </FormGroup>
            <FormGroup>
              <Alert
                alertIsOpen={error !== null}
                color="danger"
                onCloseFunc={() => {
                  setError(null);
                }}
              >
                {error}
              </Alert>
              <Button
                className="w-100 mt-4 mb-2"
                color="primary"
                type="submit"
                isFetching={isFetching}
                isDisabled={!form.terms}
              >
                {t("account_setup.buttons.submit")}
              </Button>
            </FormGroup>
          </Fragment>
        )}
      />
    </FormContainer>
  );
};

const FormContainer = styled.div`
  padding: ${space[3]}rem;
  max-width: 480px;
  margin: auto;
`;

const mapStateToProps = state => ({
  user: state.user
});

// TODO: Add dispatch for inviting users to the service center
const mapDispatchToProps = dispatch => ({
  acceptTechInvite: data => dispatch(acceptTechInvite(data)),
  login: data => dispatch(login(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteAccept))
);
