/* eslint-disable prettier/prettier */
export const FETCH_PART_BY_SERIAL_LOADING        = 'FETCH_PART_BY_SERIAL_LOADING';
export const FETCH_PART_BY_SERIAL_ERROR          = 'FETCH_PART_BY_SERIAL_ERROR';
export const FETCH_PART_BY_SERIAL_SUCCESS        = 'FETCH_PART_BY_SERIAL_SUCCESS';

export const FETCH_PART_TYPES_LOADING            = 'FETCH_PART_TYPES_LOADING';
export const FETCH_PART_TYPES_ERROR              = 'FETCH_PART_TYPES_ERROR';
export const FETCH_PART_TYPES_SUCCESS            = 'FETCH_PART_TYPES_SUCCESS';

export const FETCH_PART_MODEL_ATTRIBUTES_LOADING = 'FETCH_PART_MODEL_ATTRIBUTES_LOADING';
export const FETCH_PART_MODEL_ATTRIBUTES_ERROR   = 'FETCH_PART_MODEL_ATTRIBUTES_ERROR';
export const FETCH_PART_MODEL_ATTRIBUTES_SUCCESS = 'FETCH_PART_MODEL_ATTRIBUTES_SUCCESS';
export const RESET_PART_MODEL_ATTRIBUTES         = 'RESET_PART_MODEL_ATTRIBUTES';

export const FETCH_PART_TYPE_ATTRIBUTES_LOADING  = 'FETCH_PART_TYPE_ATTRIBUTES_LOADING';
export const FETCH_PART_TYPE_ATTRIBUTES_ERROR    = 'FETCH_PART_TYPE_ATTRIBUTES_ERROR';
export const FETCH_PART_TYPE_ATTRIBUTES_SUCCESS  = 'FETCH_PART_TYPE_ATTRIBUTES_SUCCESS';
export const RESET_PART_TYPE_ATTRIBUTES          = 'RESET_PART_TYPE_ATTRIBUTES';

export const FETCH_MODEL_DIAGNOSES_LOADING       = 'FETCH_MODEL_DIAGNOSES_LOADING';
export const FETCH_MODEL_DIAGNOSES_ERROR         = 'FETCH_MODEL_DIAGNOSES_ERROR';
export const FETCH_MODEL_DIAGNOSES_SUCCESS       = 'FETCH_MODEL_DIAGNOSES_SUCCESS';

export const FETCH_PART_TYPE_DIAGNOSES_LOADING   = 'FETCH_PART_TYPE_DIAGNOSES_LOADING';
export const FETCH_PART_TYPE_DIAGNOSES_ERROR     = 'FETCH_PART_TYPE_DIAGNOSES_ERROR';
export const FETCH_PART_TYPE_DIAGNOSES_SUCCESS   = 'FETCH_PART_TYPE_DIAGNOSES_SUCCESS';

export const RESET_DIAGNOSES                     = 'RESET_DIAGNOSES';
