import React, { Component } from "react";
import PropTypes from "prop-types";
import { colors, space } from "Theme";
import Container from "@bootstrap-styled/v4/lib/Container";
import { connect } from "react-redux";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { hideModal } from "Actions/modal";
import {
  CreateDealerLocationForm,
  CreateDealerUserForm,
  CreateTicketForm,
  UpdateDealerUserForm,
  UpdateTicketDetailsForm,
  ArchiveUserForm
} from "Containers/Forms";
import { DealershipInvitation } from "Containers";
import Heading from "../Heading";
import Button from "../Button";
import * as modaltypes from "./types";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onClose() {
    const { hideModal } = this.props;
    hideModal();
  }

  renderModalContent() {
    const {
      modal: { contentType, data }
    } = this.props;
    let Modal;
    switch (contentType) {
      case modaltypes.FORM_CREATE_DEALER_LOCATION:
        Modal = <CreateDealerLocationForm />;
        break;
      case modaltypes.FORM_CREATE_DEALER_USER:
        Modal = <CreateDealerUserForm />;
        break;
      case modaltypes.FORM_CREATE_TICKET:
        Modal = <CreateTicketForm />;
        break;
      case modaltypes.FORM_UPDATE_DEALER_USER:
        Modal = <UpdateDealerUserForm data={data} />;
        break;
      case modaltypes.FORM_UPDATE_TICKET:
        Modal = <UpdateTicketDetailsForm />;
        break;
      case modaltypes.CONFIRM_ACCEPT_DEALER_INVITE:
        Modal = <DealershipInvitation />;
        break;
      case modaltypes.CONFIRM_ARCHIVE_USER:
        Modal = <ArchiveUserForm data={data} cancel={() => this.onClose()} />;
        break;
      default:
        Modal = <h1> No modal content rendered </h1>;
        break;
    }

    return Modal;
  }

  render() {
    const {
      modal: { isOpen, title },
      t
    } = this.props;
    return (
      isOpen && (
        <ModalContainer>
          <Header>
            <HeaderContent>
              <Heading size={4}>{title}</Heading>
              <Button
                onClickFunc={() => this.onClose()}
                color="secondary"
                type="button"
                aria-label={t("modal.close")}
                width="max-content"
              >
                {t("modal.close")}
              </Button>
            </HeaderContent>
          </Header>
          <ModalContentContainer>
            <Container>
              <Row>
                <Col md={{ size: 10, offset: 1 }}>
                  {this.renderModalContent()}
                </Col>
              </Row>
            </Container>
          </ModalContentContainer>
        </ModalContainer>
      )
    );
  }
}

Modal.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    contentType: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.shape({})
  }),
  hideModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

Modal.defaultProps = {
  modal: {
    contentType: "",
    title: ""
  }
};

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100%;
  z-index: 100;
  background-color: ${colors.gray.lightest};
`;

const Header = styled.div`
  position: sticky;
  width: 100%;
  background-color: ${colors.secondary.lightest};
  color: ${colors.white.base};
  z-index: 150;
`;

const HeaderContent = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  > * {
    margin-bottom: 0;
  }
  button.btn {
    width: "max-content";
    svg {
      transform: rotate(45deg) scale(0.65);
    }
  }
`;

const ModalContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: ${space[3]}rem 0;
  ::-webkit-scrollbar {
    width: 0 px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const mapStateToProps = state => ({
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Modal));
