import React from "react";
import Logo from "../../assets/images/Logo";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // TODO: Log error somewhere
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "4px",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <a href="/" style={{ display: "block", width: 200 }}>
            <Logo />
          </a>
          <p>Something went wrong. Please refresh the page.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
