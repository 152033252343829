import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const ServiceCentersIcon = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 1H1V15H3V11H5V15H15V9H11V1ZM5 3H3V5H5V3ZM9 3H7V5H9V3ZM7 7H9V9H7V7ZM11 11H13V13H11V11Z" />
  </svg>
);

ServiceCentersIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

ServiceCentersIcon.defaultProps = {
  color: colors.gray.darkest,
  size: '34px'
};

export default ServiceCentersIcon;
