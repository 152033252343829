import { api, retryApiCall } from '../api';
import {
  FETCH_SERVICE_CENTERS_LOADING,
  FETCH_SERVICE_CENTERS_SUCCESS,
  FETCH_SERVICE_CENTERS_ERROR,
  SEARCH_SERVICE_CENTERS_LOADING,
  SEARCH_SERVICE_CENTERS_ERROR,
  SEARCH_SERVICE_CENTERS_SUCCESS
} from './types';

export const serviceCenterCallTypes = {};
export const apiCall = retryApiCall.use(serviceCenterCallTypes);

/**
 * Fetch a List of Service Centers
 */
export const fetchServiceCentersLoading = data => ({
  type: FETCH_SERVICE_CENTERS_LOADING,
  payload: data
});

export const fetchServiceCentersSuccess = data => ({
  type: FETCH_SERVICE_CENTERS_SUCCESS,
  payload: data
});

export const fetchServiceCentersFailure = data => ({
  type: FETCH_SERVICE_CENTERS_ERROR,
  payload: data
});

export const fetchServiceCenters = data => dispatch => {
  dispatch(apiCall(fetchServiceCentersLoading, fetchServiceCenters, data));
  return api
    .get(`service-centers`, {
      params: {
        ...data
      }
    })
    .then(res => {
      dispatch(fetchServiceCentersSuccess(res.data));
    })
    .catch(err => {
      dispatch(fetchServiceCentersFailure());
      return Promise.reject(err.response.status);
    });
};

export const searchServiceCentersLoading = data => ({
  type: SEARCH_SERVICE_CENTERS_LOADING,
  payload: data
});

export const searchServiceCentersFailure = data => ({
  type: SEARCH_SERVICE_CENTERS_ERROR,
  payload: data
});

export const searchServiceCentersSuccess = data => ({
  type: SEARCH_SERVICE_CENTERS_SUCCESS,
  payload: data
});

export const searchServiceCenters = data => dispatch => {
  dispatch(apiCall(searchServiceCentersLoading, searchServiceCenters, data));
  return api
    .get(`/service-centers/search`, { params: { ...data } })
    .then(res => {
      dispatch(searchServiceCentersSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(searchServiceCentersFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};